export default [
    "pencil",
    "pencil-outline",
    "square-edit-outline",
    "table-edit",
    "align-horizontal-center",
    "align-horizontal-left",
    "align-horizontal-right",
    "align-vertical-bottom",
    "align-vertical-center",
    "align-vertical-top",
    "format-align-bottom",
    "format-align-top",
    "format-align-center",
    "format-align-justify",
    "format-align-left",
    "format-align-right",
    "format-align-middle",
    "format-float-left",
    "format-float-center",
    "format-float-right",
    "format-float-none",
    "format-list-bulleted",
    "format-list-bulleted-square",
    "format-list-bulleted-triangle",
    "format-list-bulleted-type",
    "format-list-checkbox",
    "format-list-numbered",
    "format-list-numbered-rtl",
    "format-list-checks",
    "format-horizontal-align-center",
    "format-horizontal-align-left",
    "format-horizontal-align-right",
    "format-vertical-align-bottom",
    "format-vertical-align-center",
    "format-vertical-align-top",
    "border-all",
    "border-bottom",
    "border-top",
    "border-left",
    "border-right",
    "border-vertical",
    "border-horizontal",
    "border-inside",
    "border-outside",
    "border-none",
    "border-all-variant",
    "border-bottom-variant",
    "border-top-variant",
    "border-left-variant",
    "border-right-variant",
    "border-none-variant",
    "format-page-break",
    "content-copy",
    "content-paste",
    "content-cut",
    "delete",
    "delete-outline",
    "trash-can",
    "trash-can-outline",
    "format-letter-case",
    "format-letter-case-upper",
    "format-letter-case-lower",
    "format-letter-ends-with",
    "format-letter-matches",
    "format-letter-starts-with",
    "format-annotation-minus",
    "format-annotation-plus",
    "format-font",
    "format-underline",
    "format-overline",
    "format-bold",
    "format-italic",
    "format-text",
    "format-size",
    "format-section",
    "format-clear",
    "format-color-fill",
    "format-color-text",
    "format-color-highlight",
    "sort-ascending",
    "sort-descending",
    "format-strikethrough",
    "format-strikethrough-variant",
    "vector-square",
    "format-textbox",
    "format-quote-close",
    "refresh",
    "magnify",
    "magnify-scan",
    "magnify-plus-outline",
    "magnify-minus-outline",
    "filter",
    "filter-outline",
    "plus",
    "plus-outline",
    "plus-thick",
    "plus-circle",
    "plus-circle-outline",
    "plus-circle-multiple",
    "plus-circle-multiple-outline",
    "plus-box",
    "plus-box-outline",
    "plus-box-multiple",
    "plus-box-multiple-outline",
    "minus",
    "format-quote-open",
    "format-quote-close",
    "format-quote-open-outline",
    "format-quote-close-outline",
    "format-wrap-inline",
    "format-wrap-square",
    "format-wrap-tight",
    "format-wrap-top-bottom",
    "code-parentheses",
    "code-brackets",
    "code-tags",
    "xml",
    "code-braces",
    "code-json",
]