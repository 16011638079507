export default[
  "information",
  "information-outline",
  "information-variant",
  "folder-information",
  "folder-information-outline",
  "alert",
  "alert-outline",
  "alert-box",
  "alert-box-outline",
  "alert-circle",
  "alert-circle-outline",
  "alert-decagram",
  "alert-decagram-outline",
  "alert-octagon",
  "alert-octagon-outline",
  "alert-octagram",
  "alert-octagram-outline",
  "shield-alert",
  "shield-alert-outline",
  "folder-alert",
  "folder-alert-outline",
  "file-alert",
  "file-alert-outline",
  "check",
  "check-bold",
  "check-circle",
  "check-circle-outline",
  "check-decagram",
  "check-decagram-outline",
  "shield-check",
  "shield-check-outline",
  "cancel",
  "help",
  "help-box",
  "help-circle",
  "help-circle-outline",
  "progress-question",
  "lightbulb-on",
  "lightbulb-off",
];