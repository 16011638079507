export default [
  "database",
  "nas",
  "folder",
  "folder-outline",
  "file",
  "file-outline",
  "folder-zip",
  "table",
  "chart-arc",
  "chart-bar",
  "chart-bar-stacked",
  "chart-bell-curve-cumulative",
  "chart-line",
  "chart-pie",
  "chart-scatter-plot",
  "download-network-outline",
  "briefcase-download",
  "monitor-dashboard",
  "sitemap",
  "file-chart",
  "file-chart-outline",
  "book-open",
  "book-open-outline",
  "cloud-print",
  "cloud-print-outline",
];