export default [
  "circle",
  "circle-double",
  "circle-outline",
  "cube",
  "cube-outline",
  "decagram",
  "decagram-outline",
  "drawing",
  "drawing-box",
  "ellipse",
  "ellipse-outline",
  "heart",
  "heart-outline",
  "heart-multiple",
  "heart-multiple-outline",
  "hexagon",
  "hexagon-multiple",
  "hexagon-outline",
  "hexagram",
  "hexagram-outline",
  "octagon",
  "octagon-outline",
  "octagram",
  "octagram-outline",
  "pentagon",
  "pentagon-outline",
  "rectangle",
  "rectangle-outline",
  "rhombus",
  "rhombus-medium",
  "rhombus-medium-outline",
  "rhombus-outline",
  "rhombus-split",
  "rhombus-split-outline",
  "shape",
  "shape-circle-plus",
  "shape-outline",
  "shape-plus",
  "shape-polygon-plus",
  "shape-rectangle-plus",
  "shape-square-plus",
  "square",
  "square-medium",
  "square-medium-outline",
  "square-outline",
  "star",
  "star-check",
  "star-check-outline",
  "star-four-points",
  "star-four-points-outline",
  "star-half",
  "star-minus",
  "star-minus-outline",
  "star-outline",
  "star-plus",
  "star-plus-outline",
  "star-remove",
  "star-remove-outline",
  "star-three-points",
  "star-three-points-outline",
  "triangle",
  "triangle-outline",
  "square-rounded",
  "square-rounded-outline",
  "crop-square",
  "dots-square",
];