export default [
  "cart",
  "cart-outline",
  "cart-variant",
  "cellphone",
  "volume-high",
  "volume-low",
  "volume-minus",
  "volume-plus",
  "volume-off",
  "signal",
  "camera",
  "home",
  "home-outline",
  "cog-outline",
  "cog",
  "cog-off",
  "cog-off-outline",
  "cog-transfer",
  "cog-transfer-outline",
  "apps",
  "apps-box",
  "gauge",
  "robot",
  "account",
  "account-circle",
  "account-multiple",
  "account-box-multiple",
  "account-box",
  "account-box-multiple-outline",
  "account-box-outline",
  "badge-account-horizontal",
  "badge-account-horizontal-outline",
  "image-filter-drama",
  "cloud",
  "cloud-download",
  "cloud-check",
  "cloud-alert",
  "cloud-sync",
  "cloud-search",
  "cloud-outline",
  "cloud-download-outline",
  "cloud-check-outline",
  "cloud-sync-outline",
  "cloud-search-outline",
  "cloud-upload-outline",
  "upload",
  "clock-outline",
  "clock",
  "alarm",
  "history",
  "cached",
  "key",
  "key-chain",
  "lock",
  "lock-check",
  "lock-check-outline",
  "lock-reset",
  "lock-open",
  "lock-open-outline",
  "lock-open-check",
  "lock-open-check-outline",
  "lock-outline",
  "wifi",
  "currency-usd",
  "currency-cny",
  "gender-female",
  "gender-male",
  "gift",
  "gift-open",
  "gift-outline",
  "gift-open-outline",
  "gift-outline",
  "package-variant-closed",
  "package-variant",
  "google-downasaur",
  "apple",
  "compass",
  "compass-outline",
  "map-marker",
  "map-marker-outline",
  "message",
  "message-outline",
  "qrcode",
  "qrcode-scan",
  "printer",
  "view-dashboard",
  "view-dashboard-outline",
  "storefront",
  "storefront-outline",
  "tag",
  "tag-outline",
  "tag-multiple",
  "tag-multiple-outline",
  "thumb-up",
  "thumb-down",
  "thumb-up-outline",
  "thumb-down-outline",
  "connection",
  "source-branch",
  "export",
  "reload",
  "calendar-blank",
  "calendar-blank-outline",
  "inbox",
  "reply",
  "share",
  "share-variant",
  "share-variant-outline",
  "image-size-select-actual",
  "resize-bottom-right",
  "console",
  "folder-open-outline",
  "parachute-outline",
  "face-man-profile",
  "twitter",
  "facebook",
  "linkedin",
  "instagram",
  "google",
  "google-chrome",
  "google-plus",
  "google-play",
  "google-drive",
];