export default [
  "subdirectory-arrow-left",
  "subdirectory-arrow-right",
  "arrow-up",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-top-left",
  "arrow-top-right",
  "arrow-bottom-left",
  "arrow-bottom-right",
  "arrow-up-thick",
  "arrow-down-thick",
  "arrow-left-thick",
  "arrow-right-thick",
  "arrow-top-left-thick",
  "arrow-top-right-thick",
  "arrow-bottom-left-thick",
  "arrow-bottom-right-thick",
  "arrow-up-bold",
  "arrow-down-bold",
  "arrow-left-bold",
  "arrow-right-bold",
  "arrow-up-bold-box",
  "arrow-down-bold-box",
  "arrow-left-bold-box",
  "arrow-right-bold-box",
  "arrow-up-circle",
  "arrow-down-circle",
  "arrow-left-circle",
  "arrow-right-circle",
  "arrow-up-circle-outline",
  "arrow-down-circle-outline",
  "arrow-left-circle-outline",
  "arrow-right-circle-outline",
  "arrow-up-thin-circle-outline",
  "arrow-down-thin-circle-outline",
  "arrow-left-thin-circle-outline",
  "arrow-right-thin-circle-outline",
  "arrow-up-bold-circle-outline",
  "arrow-down-bold-circle-outline",
  "arrow-left-bold-circle-outline",
  "arrow-right-bold-circle-outline",
  "arrow-up-bold-hexagon-outline",
  "arrow-down-bold-hexagon-outline",
  "arrow-left-bold-hexagon-outline",
  "arrow-right-bold-hexagon-outline",
  "arrow-up-drop-circle",
  "arrow-down-drop-circle",
  "arrow-left-drop-circle",
  "arrow-right-drop-circle",
  "menu-up",
  "menu-down",
  "menu-left",
  "menu-right",
  "menu-up-outline",
  "menu-down-outline",
  "menu-left-outline",
  "menu-right-outline",
  "chevron-up",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-double-up",
  "chevron-double-down",
  "chevron-double-left",
  "chevron-double-right",
  "chevron-triple-up",
  "chevron-triple-down",
  "chevron-triple-left",
  "chevron-triple-right",
  "arrow-up-down",
  "arrow-left-right",
  "arrow-up-down-bold",
  "arrow-left-right-bold",
  "arrow-up-down-bold-outline",
  "arrow-left-right-bold-outline",
  "unfold-less-vertical",
  "unfold-more-vertical",
  "unfold-less-horizontal",
  "unfold-more-horizontal",
  "page-first",
  "page-last",
  "cursor-move",
];