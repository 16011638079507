<template>
  <v-card class="text-center" max-width="700">
    <v-card-title class="ma-0 pa-1">
        <v-row class="overflow-hidden pa-0 ma-0">
            <v-col class="pa-0 ma-0" cols="12">
                <v-tabs v-model="tab" background-color="transparent"
                        color="custom-accent"
                        center-active
                        centered
                        show-arrows>
                    <v-tab
                        v-for="item in items"
                        :key="item.tab"
                    >
                        {{ $t(item.tab) }}
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
    </v-card-title>
    <v-card-text class="ma-0 pa-1" style="height:300px;overflow-y:auto;">
        <v-tabs-items v-model="tab">
            <v-text-field style="width:100%;z-index:300;"
              v-model="Search.SearchTerm"
              :label="$t('search')"
              :placeholder="$t('search')"
              class="ma-auto pa-5 custom-white"
              color="custom-accent"
              rounded
              outlined
              dense
              append-icon="mdi-magnify"
              hide-details
            >
            </v-text-field>
            <v-tab-item v-for="item in items" :key="item.tab">
                <v-tooltip v-for="(icon,i) in item.content" :key="i" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" :dark="selected_icon==icon" :class="{'custom-accent': selected_icon==icon}" @click="selectIcon(icon)" text :title="icon"><v-icon>{{icon}}</v-icon></v-btn>
                  </template>
                  <span>{{icon}}</span>
                </v-tooltip>
            </v-tab-item>
        </v-tabs-items>   
    </v-card-text>
  </v-card>
</template>
<script>
import direction from "../../plugins/Icons/Direction";
import indication from "../../plugins/Icons/Indication";
import editing from "../../plugins/Icons/Editing";
import data from "../../plugins/Icons/Data";
import shape from "../../plugins/Icons/Shape";
import common from "../../plugins/Icons/Common";
export default {
  props: ["value"],
  data() {
    return {
      tab: null,
      Search:{
        SearchTerm:'',
        SearchBox:false,
        SearchLoading:false,
      },
      selected_icon: '',
    };
  },
  created(){
    if(this.value)
      this.selected_icon=this.value;
  },
  mounted() {
  },
  computed: {
    items() {
        let all = [...direction, ...indication, ...editing, ...data, ...shape, ...common];
        return [
            { tab: 'all', content: all.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
            { tab: 'direction', content: direction.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
            { tab: 'indication', content: indication.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
            { tab: 'editing', content: editing.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
            { tab: 'data', content: data.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
            { tab: 'shape', content: shape.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
            { tab: 'common', content: common.filter((item)=>{return item.toLowerCase().includes(this.Search.SearchTerm)}).map((i) => this.decodeIcon(i)) },
        ];
    },
  },
  watch:{
  },
  methods: {
    selectIcon(icon){
        this.selected_icon = icon;
        this.$emit('input', icon)
    },
    decodeIcon(icon) {
        return 'mdi-'+icon;
    }
  }
};
</script>